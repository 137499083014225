.ant-btn {
    &.margin-top-small {
        margin-top: 4px;
    }

    &.margin-top-medium {
        margin-top: 12px;
    }

    &.margin-top-large {
        margin-top: 20px;
    }

    &.margin-bottom-small {
        margin-bottom: 4px;
    }

    &.margin-bottom-medium {
        margin-bottom: 12px;
    }

    &.margin-bottom-large {
        margin-bottom: 20px;
    }

    &.ant-btn-primary {
        .lucide {
            margin-right: 5px;
            margin-left: -8px;
        }
    }
}
