.ant-layout-header {
  padding: 0;
  height: 50px;
}

.Header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  color: #fff;
  height: 50px;
  padding-inline: 10px;
  background-color: $color-primary;

  div {
    display: flex;
    align-items: center;

    img {
      height: 34px;
      margin-right: 10px;
    }

    .SelectWrapper {
      margin-right: 10px;
      .ant-select {
        min-width: 200px;
      }
    }

    button {
      margin-top: -8px
    }
  }

}
