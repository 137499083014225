.Footer {
  display: flex;
  justify-content: center;
  align-items: center;

  color: #fff;
  height: 50px;
  padding-inline: 20px;
  background-color: $color-primary;
}
