.CenteredContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    min-height: 100%;
    min-width: 100%;

    > div {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;

        background-color: #ffffff;

        margin: 20px;
        padding: 20px;
    }

    > div.Box {
        @extend .Shadow;
        width: 480px;
        min-height: 440px;
    }

    @media screen and (max-width: 520px) {
        > div.Box {
            width: 100%;
            min-width: 260px;
        }
    }

    @media screen and (min-width: 521px) and (max-width: 800px) {
        > div.Box {
            max-width: 480px;
        }
    }
}
