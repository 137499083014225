.ant-table-wrapper {
    border: 1px solid #d9d9d9;
    border-radius: 4px;
}

.ant-table-title {
    .ant-typography {
        line-height: 1;
        margin-top: 0.5em;
    }
}
