.PostAuthContainer {
    &.ant-layout {
        overflow: hidden;
        width: 100%;
        height: 100%;
        max-Width: 100%;
    }

    // > .ant-layout {
    //     height: 100%;
    // }

    > .ant-layout-has-sider > aside {
        background-color: #e5f5f3;
        color: #333;
    }

    main {
        @extend .padding-medium;

        background-color: #fafafa;
        color: #333;
        overflow-y: auto;
    }
}

.ant-drawer {
    .ant-drawer-body {
        background-color: #e5f5f3;
        color: #333;
    }
}
