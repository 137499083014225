.PreAuthContainer {
    @extend .CenteredContainer;

    background-image: url("../assets/background.png");
    background-color: #fafafa;

    > div {
        & > img {
            height: 60px;
            margin-bottom: 20px;
        }

        footer {
            position: absolute;
            display:flex;
            justify-content: space-around;

            bottom: 0;

            height: 50px;
            width: 100%;
            background-color: $color-primary;
            border-bottom-left-radius: 17px;
            border-bottom-right-radius: 17px;

            color: #ffffff;

            line-height: 50px;
            text-align: center;

            a {
                color: #ffffff;
                text-decoration: none;
            }
        }
    }
}
