@import "styles/ant-design/button";
@import "styles/ant-design/form-item";
@import "styles/ant-design/table";

/** @todo move this */
.ant-input-number,
.ant-input-number-affix-wrapper {
    width: 100%;
}


.Filters {
    .ant-collapse-header {
        padding: 3px 8px !important;
    }
}

.ant-drawer {
    .ant-drawer-body {
        padding: 0;
    }
}
