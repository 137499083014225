@import "styles/components/elements/icons";

.SelectWrapper {
    &.full {
        width: 100%;
    }

    input[type=search] {
        padding-left: 20px;
    }
}


