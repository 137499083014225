.lucide {
    margin-bottom: -2px;

    &.margin-bottom-small {
        margin-bottom: 6px;
    }

    &.margin-bottom-medium {
        margin-bottom: 14px;
    }

    &.margin-bottom-large {
        margin-bottom: 22px;
    }
}
