.ant-form-item {
    &.form-item-two {
        .ant-form-item {
            display: inline-block;
            width: calc(50% - 8px);
            margin-bottom: 0;

            &:not(:first-of-type) {
                margin-left: 16px;
            }
        }
    }

    &.FilterModifier {
        height: 20px;

    }
}

.ant-input-group-addon:has(.FilterModifier) {
    padding: 0;
}

.ant-select-item-option-content {
    padding: 0 20px 0 5px;
}
