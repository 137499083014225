* {
    z-index: 0;
}

html, body, #root {
    padding: 0;
    margin: 0;
    height: 100%;
    width: 100%;
}

body {
  margin: 0;
  font-family: "open sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.multiline {
  white-space: pre-wrap;
}

@import "styles/variables";
@import "styles/text";
@import "styles/spacing";
@import "styles/app-container";
@import "styles/pre-auth-container";
@import "styles/post-auth-container";


@import "styles/components";
@import "styles/layout/header";
@import "styles/layout/footer";
@import "styles/lucide-react";
@import "styles/ant-design";
@import "styles/design";



/** @todo */
.ant-btn-primary {
  .ant-btn-icon {
    svg {
      stroke: #fff;
    }
  }
}



/** @todo */
.SelectWrapper {
  display: inline-block;
  position: relative;

  .prefix-icon-wrapper {
    position: absolute;
    z-index: 1;
    width: 2.5rem;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  & .ant-select .ant-select-selector {
    padding-left: calc(2.5rem - 8px);
  }
}

/** @todo */

.LoadingIndicator {
    text-align: center;
    font-size: 2em;

    svg {
        vertical-align: 0.08em;
    }

    &[data-size=xs] {
        svg {
            height: 0.5em;
        }
    }

    &[data-size=sm] {
        svg {
            height: 0.8em;
        }
    }
}


.LoadingIndicator {
    display: block;
    text-align: center;

    &.inline-block {
        display: inline-block;
        margin-left: 10px;
    }

    &[data-size="sm"] {
        height: 16px;
        font-size: 16px;
    }

    &[data-size="smd"] {
        height: 20px;
        font-size: 20px;
    }

    &[data-size="md"] {
        height: 24px;
        font-size: 24px;
    }

    &[data-size="lg"] {
        height: 36px;
        font-size: 36px;
    }

    &[data-size="xl"] {
        height: 48px;
        font-size: 48px;
    }
}



/** @todo */
.ant-menu-light .ant-menu-item-selected a,
.ant-menu-light > .ant-menu .ant-menu-item-selected a,
.ant-menu-light .ant-menu-item-selected a:hover,
.ant-menu-light > .ant-menu .ant-menu-item-selected a:hover {
    color: #000000;
    opacity: 0.8;
}

.PaymentButton {
    box-shadow: 0px 0px 10px 1px rgba(0, 67, 70, 0.6);

    border-radius: 5px;
    // width: 40%;
    // height: 90px;

    img {
        height: 70px;
        margin: 0 auto;
    }

    &:hover {
        box-shadow: 0px 0px 5px 1px rgba(0, 67, 70, 0.6);
    }
    &:active {
        box-shadow: 0px 0px 2px 1px rgba(0, 67, 70, 0.6);
    }
}

.Page {
    display: flex;
    flex-direction: column;
    gap: 1em;
}


.FloatingTitle {
    background-color: #ffffff;
    border: 1px solid #d9d9d9;
    border-radius: 4px;

    padding: 8px 8px;

    .ant-typography {
        line-height: 1;
        margin-top: 0.5em;
    }

}


.CardList {
    .ant-card {
        .ant-card-body {
            .ant-typography {
                line-height: 1;
                margin-top: 0.5em;
            }
        }
        .ant-card-actions {
            gap: 1em;
        }
    }
}

.Filters {
    form {
        .ant-row {
            .ant-col:not(:first-of-type) {
                // Subsequent filters pad left on label
                .ant-form-item-label {
                    padding-left: 8px;
                }
            }

            .ant-form-item-control {

                .ant-input-wrapper {
                    // padding: 0 8px;
                }

                .ant-select {
                    // padding: 0 8px;
                }
            }
        }
    }
}

.NoClubSelected {
    height: 100%;
}
