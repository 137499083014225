a[href^="tel:"]:hover > svg {
    animation: shake 1s;
    animation-iteration-count: infinite;
}

// a[href^="mailto:"]:hover > .Icon {
//     animation: send 1s;
//     animation-iteration-count: infinite;
//     overflow: hidden;
// }

// @keyframes send {
//     0% { transform: translate(0px, 0px) rotate(0deg); }
//     30% { transform: translate(-4px, 0px) rotate(0deg); }
//     50% { transform: translate(100%, 0px) rotate(0deg); }
//     100% { transform: translate(100%, 0px) rotate(0deg); }
// }

@keyframes shake {
    0% { transform: translate(1px, 1px) rotate(0deg); }
    10% { transform: translate(1px, -1px) rotate(3deg); }
    20% { transform: translate(-1px, -1px) rotate(-3deg); }
    30% { transform: translate(-1px, 1px) rotate(0deg); }
    40% { transform: translate(-1px, 0px) rotate(-3deg); }
    50% { transform: translate(0px, 0px) rotate(3deg); }
    60% { transform: translate(0px, 0px) rotate(0deg); }
    70% { transform: translate(0px, 0px) rotate(0deg); }
    80% { transform: translate(0px, 0px) rotate(0deg); }
    90% { transform: translate(0px, 0px) rotate(0deg); }
    100% { transform: translate(0px, 0px) rotate(0deg); }
}

@keyframes ring {
    0% { transform: translate(1px, 1px) rotate(135deg); }
    10% { transform: translate(1px, -1px) rotate(138deg); }
    20% { transform: translate(-1px, -1px) rotate(132deg); }
    30% { transform: translate(-1px, 1px) rotate(135deg); }
    40% { transform: translate(-1px, 0px) rotate(132deg); }
    50% { transform: translate(0px, 0px) rotate(138deg); }
    60% { transform: translate(0px, 0px) rotate(135deg); }
    70% { transform: translate(0px, 0px) rotate(135deg); }
    80% { transform: translate(0px, 0px) rotate(135deg); }
    90% { transform: translate(0px, 0px) rotate(135deg); }
    100% { transform: translate(0px, 0px) rotate(135deg); }
}
