$spacing-small: 8px;
$spacing-medium: 16px;
$spacing-large: 24px;

.float-right {
    float: right;
}

.margin-top-small {
    margin-top: $spacing-small;
}

.margin-top-medium {
    margin-top: $spacing-medium;
}

.margin-top-large {
    margin-top: $spacing-large;
}

.margin-bottom-small {
    margin-bottom: $spacing-small;
}

.margin-bottom-medium {
    margin-bottom: $spacing-medium;
}

.margin-bottom-large {
    margin-bottom: $spacing-large;
}

.padding-small {
    padding: $spacing-small;
}

.padding-medium {
    padding: $spacing-medium;
}

.padding-large {
    padding: $spacing-large;
}

.padding-top-small {
    padding-top: $spacing-small;
}

.padding-top-medium {
    padding-top: $spacing-medium;
}

.padding-top-large {
    padding-top: $spacing-large;
}

.padding-bottom-small {
    padding-bottom: $spacing-small;
}

.padding-bottom-medium {
    padding-bottom: $spacing-medium;
}

.padding-bottom-large {
    padding-bottom: $spacing-large;
}
